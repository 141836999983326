import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import { Layout } from '../components/Layout'
import { ColumnLayout } from "../components/ColumnLayout"
import { PostItem } from "../components/PostItem"
import Styles from "../styles/templates/TagResultPageTemplate.module.scss"

const TagResultPageTemplate = ( {
    data,
    pageContext
}: any ) => {

    const posts = data.allMarkdownRemark.edges
    const tag = pageContext.tag
    const title = data.site.siteMetadata.title
    const totalCount = data.allMarkdownRemark.totalCount
    const tagHeader = `"${ tag }"のタグがついたページは${ totalCount }つあります`

    return (
        <Layout>
            <ColumnLayout>
                <section>
                    <Helmet title={ `${ tag } | ${ title }` } />
                    <div>
                        <h3 className={ Styles.heading3 }>{ tagHeader }</h3>
                        <p className={ Styles.all_tag_link }>
                            <Link to="/tags/">タグ一覧</Link>
                        </p>
                        <ul className={ Styles.tag_list }>
                            { posts.map( ( post: any ) => <PostItem post={ post.node } key={ post.node.id } /> ) }
                        </ul>

                    </div>
                </section>
            </ColumnLayout>
        </Layout>
    );

}

export default TagResultPageTemplate

export const tagPageQuery = graphql`
    query TagPage($tag: String) {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(
            limit: 1000
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { 
                frontmatter: { 
                    tags: { in: [$tag], },
                    templateKey: { eq: "post" },
                } 
            }
        ) {
            totalCount
            edges {
                node {
                    fields {
                        slug
                    }
                    frontmatter {
                        path
                        title
                        description
                        featuredpost
                        featuredimage{
                            childImageSharp {
                                fluid(maxWidth: 120, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
